import React from "react";
import { Button, Row, Col } from "antd";
import { useParallax } from "react-scroll-parallax";
import { FormattedMessage } from "react-intl";

export default function SwappingStation() {
  const parallax_left = useParallax({
    easing: "easeOutQuad",
    translateX: [-80, 0],
  });

  const parallax_right = useParallax({
    easing: "easeOutQuad",
    translateX: [80, 0],
  });

  return (
    <>
      <section id="swappingStation" className="bg1" style={{ padding: "6% 0", background: "url(./image/BG-01.jpg) no-repeat fixed center" }}>
        <Row justify="space-around" align="middle" className="row-content">
          <Col xs={24} sm={24} md={12}>
            <div ref={parallax_left.ref}>
              <img src="./image/cabinet.png" width={"100%"}></img>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div ref={parallax_right.ref}>
              <h1 className="h1-evBike">
                <FormattedMessage
                  id="TITLE_SWAPPING_STATION"
                  defaultMessage="Swapping Station"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </h1>
              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_SWAPPING_STATION1"
                  defaultMessage="Battery swap station with high-volume charging capability"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_SWAPPING_STATION2"
                  defaultMessage="It has Sanitary measures and is safe for users."
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_SWAPPING_STATION3"
                  defaultMessage=""
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
              <div className="text-evBike">
                <FormattedMessage
                  id="DESCRIPTION_SWAPPING_STATION4"
                  defaultMessage=""
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </div>
              {/* <Button className="btn">
                <FormattedMessage
                  id="DETAILS"
                  defaultMessage="details"
                  values={{
                    code: (word) => <strong>{word}</strong>,
                  }}
                />
              </Button> */}
            </div>
          </Col>
        </Row>
        <br /> <br />
      </section>
    </>
  );
}
