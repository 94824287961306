import React, { useEffect } from "react";
import NavBar from "../../Views/NavBar";
import News1 from "./News1";
import News2 from "./News2";
import ContactUs from "../../Views/ContactUs";
import { useSearchParams } from 'react-router-dom'
import AboutUs from "../../Views/AboutUs";
function App() {
    const [searchParams, setSearchParams] = useSearchParams()
    var id = searchParams.get('id');

    return (
        <>
            <div className="header">
                <div className="">
                    <nav>
                        <NavBar />
                    </nav>
                </div>
            </div>
            <div className="container" >
                {
                    id == 1 ? <News1/> :
                        id == 2 ? <News2/> :
                            <News1/>


                }
                <ContactUs />
            </div>

        </>
    );
}

export default App;
