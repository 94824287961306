import React from "react";

import NavBar from "../../Views/NavBar";
import AboutUs from "../../Views/AboutUs";
import ContactUs from "../../Views/ContactUs";

function App() {
  return (
    <>
      <div className="header">
        <div className="">
          <nav>
            <NavBar />
          </nav>
        </div>
      </div>
      <div className="container">

        <AboutUs/>
        <ContactUs/>
      </div>

    </>
  );
}

export default App;
