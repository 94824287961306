import React, { useState } from "react";
import { IntlProvider } from "react-intl";

import Thai from "./lang/th.json";
import English from "./lang/en.json";

export const Context = React.createContext();

var userLang = navigator.language || navigator.userLanguage || "en-gb";

const languageCurrent = (value) => {
  if(value.includes("th")) return "th-th";
  else return "en-gb";
};

const local = localStorage.getItem("locale")
  ? localStorage.getItem("locale")
  : languageCurrent(userLang);

let lang;
if (local === "th-th") {
  lang = Thai;
}  else {
  lang = English;
}

const Wrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  function selectLanguage(e) {
    const newLocale = e;
    setLocale(newLocale);
    localStorage.setItem("locale", newLocale);
    if (newLocale === "th-th") {
      setMessages(Thai);
    }  else {
      setMessages(English);
    }
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
