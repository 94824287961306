import React, { useContext } from 'react'
import { Slide } from "react-slideshow-image";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { IoIosArrowBack } from "react-icons/io";
import { Context } from "../../../Wrapper";

export default function Index() {
  const context = useContext(Context);

  const properties = {
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    indicators: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "25vh",
    borderRadius: 8,
    margin: 8
  };
  const slideImages = [
    {
      image: "./image/news1-1.jpg",
      caption: "Slide 1",
    },
    {
      image: "./image/news1-2.jpg",
      caption: "Slide 2",
    },
    {
      image: "./image/news1-3.jpg",
      caption: "Slide 2",
    },
    {
      image: "./image/news1-4.jpg",
      caption: "Slide 4",
    },
    {
      image: "./image/news1-5.jpg",
      caption: "Slide 5",
    }
  ];

  return (
    <div style={{ padding: "36px 8%" }}>
      <div >
        <Link to="/#news" className='text-news'>
          <Button shape="circle" className='ant-btn-lang' >
            <IoIosArrowBack style={{ fontSize: 20, fontWeight: "bold", paddingRight: 2 }} />
          </Button>
        </Link>
      </div>

      {
        context.locale === "th-th" ?
          <div>
            <h1 className='h1-news' style={{ textAlign: 'center' }}>AS MaCharge ยกระดับบริการ ผ่านเกณฑ์ผู้ประกอบการท่องเที่ยว “ระดับ 5 ดาว”</h1>

            <div style={{ textAlign: 'center' }}>
              <img
                src="./image/news1.jpg"
                style={{ margin: "20px 0", width: "100%", maxWidth: 450, height: "auto", borderRadius: 8 }}
              ></img>
            </div>

            <div >
              <div className='text-news' style={{ textIndent: "2.5em" }}>
                บริษัท เอเอส มาชาร์จ จำกัด (AS MaCharge) ผู้ให้บริการเช่ารถจักรยานยนต์ไฟฟ้า พร้อมสถานีสับเปลี่ยนแบตเตอรี่ที่เกาะสมุย จังหวัดสุราษฎร์ธานี ผ่านเกณฑ์ ”โครงการยกระดับผู้ประกอบการสู่มาตรฐานการท่องเที่ยวยั่งยืน”  หรือ Sustainable Tourism Acceleration Rating STAR “ดาวแห่งความยั่งยืน” ระดับ 5 ดาว  โดยมีนายยุทธศักดิ์ สุภสร ผู้ว่าการการท่องเที่ยวแห่งประเทศไทย ททท. ให้เกียรติเป็นประธานในพิธีมอบ
              </div>

              <div className='text-news' style={{ textIndent: "2.5em" }}>
                สำหรับโครงการดังกล่าว จัดขึ้นเพื่อยกระดับห่วงโซ่อุปทานในอุตสาหกรรมท่องเที่ยวสู่มาตรฐานการท่องเที่ยวยั่งยืน ภายใต้แนวคิด “มาร่วมสร้างท่องเที่ยวไทยให้ยั่งยืน” สอดรับเป้าหมายการพัฒนาที่ยั่งยืน Sustainable Tourism Goals : STGs พร้อมผลักดันให้เกิดการเปลี่ยนแปลงอย่างสมดุลทุกมิติก้าวสู่การเป็น High Value and Sustainable Tourism โดย AS MaCharge พร้อมที่จะร่วมเป็นส่วนหนึ่งในการเป็นผู้ประกอบการที่ช่วยสนับสนุนการท่องเที่ยวแบบยั่งยืน และมีส่วนร่วมในการพลิกฟื้นอุตสาหกรรมท่องเที่ยวให้กลับมาอย่างมั่นคงและแข็งแรงต่อไป  งานดังกล่าวจัดขึ้น ณ ห้องจารุวัสตร์ ชั้น 10 อาคารการท่องเที่ยวแห่งประเทศไทย ถนนเพชรบุรี กรุงเทพฯ เมื่อเร็ว ๆ นี้
              </div>
            </div>

            <div>
              <Slide {...properties} >
                {slideImages.map((slideImage, index) => (
                  <div key={index}>
                    <div
                      style={{
                        ...divStyle,
                        backgroundImage: `url(${slideImage.image})`,
                      }}
                    >
                    </div>
                  </div>
                ))}
              </Slide>
            </div>


          </div>
          :

          <div>
            <h1 className='h1-news' style={{ textAlign: 'center' }}>AS MaCharge raises the level of service receiving "5-star" Sustainable Tourism Acceleration</h1>

            <div style={{ textAlign: 'center' }}>
              <img
                src="./image/news1.jpg"
                style={{ margin: "20px 0", width: "100%", maxWidth: 450, height: "auto", borderRadius: 8 }}
              ></img>
            </div>

            <div >
              <div className='text-news' style={{ textIndent: "2.5em" }}>
              AS MaCharge Co.,Ltd. (AS MaCharge), e-bike rental service provider with battery swapping stations on Samui Island, Surat Thani Province received the 5-star Sustainable Tourism Acceleration Rating (STAR) criteria and Mr. Yuthasak Supasorn, Governor of the Tourism Authority of Thailand, TAT, honored as chairman of the award ceremony. 
              </div>

              <div className='text-news' style={{ textIndent: "2.5em" }}>
              This project was held to develop the supply chain in the tourism industry to sustainable tourism standards under the concept of "Come together to create Thailand sustainable tourism" aligned with the Sustainable Tourism Goals: STGs, ready to push for balanced change in every way towards becoming “High Value and Sustainable Tourism”. AS MaCharge is ready to be a part of society that helps support sustainable tourism and participate in turning the tourism industry back to be more stable and stronger. The event was recently held at Jaruwat Room, 10th Floor, Tourism Authority of Thailand Building, Phetchaburi Road, Bangkok.
              </div>
            </div>

            <div>
              <Slide {...properties} >
                {slideImages.map((slideImage, index) => (
                  <div key={index}>
                    <div
                      style={{
                        ...divStyle,
                        backgroundImage: `url(${slideImage.image})`,
                      }}
                    >
                    </div>
                  </div>
                ))}
              </Slide>
            </div>


          </div>
      }

    </div >
  )
}
