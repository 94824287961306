import React from 'react'
import { Menu, Select, Button, Row, Col, Space, Dropdown, Popover } from "antd";
import { GB, TH } from "country-flag-icons/react/3x2";
import { FormattedMessage } from "react-intl";
import { Context } from "../Wrapper";
import { Link } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai"
const { Option } = Select;

export default function MenuDesktop({ handleClickScroll, context }) {


  const styleFlagIcon = {
    width: "30px",
    height: "30px",
    marginRight: "5px",
    cursor: 'pointer'
  };

  const handleMenuClick = (e) => {
    console.log('click', e);
    if (e.key === "2") {
      context.selectLanguage("th-th")
    } else {
      context.selectLanguage("en-gb")
    }
  };

  // const items = [
  //   {
  //     label: 'EN',
  //     key: '1',
  //     icon: <GB style={styleFlagIcon} />,
  //   },
  //   {
  //     label: 'TH',
  //     key: '2',
  //     icon: <TH style={styleFlagIcon} />,
  //   },
  // ];

  // const menuProps = {
  //   items,
  //   onClick: handleMenuClick,
  // };

  const content = (
    <Row style={{ width: 110 }}>
      <Col xs={10} onClick={() => context.selectLanguage("th-th")}>
        <TH style={styleFlagIcon} />
      </Col>
      <Col xs={14} onClick={() => context.selectLanguage("th-th")} style={{ fontFamily: "KanitSemiBold", paddingTop: 4, fontSize: 16, cursor: 'pointer' }}>
        TH
      </Col>

      <Col xs={10} onClick={() => context.selectLanguage("en-gb")}>
        <GB style={styleFlagIcon} />
      </Col>
      <Col xs={14} onClick={() => context.selectLanguage("en-gb")} style={{ fontFamily: "KanitSemiBold", paddingTop: 4, fontSize: 16, cursor: 'pointer' }}>
        EN
      </Col>
    </Row>
  );

  return (
    <Menu
      mode="horizontal"
      style={{ display: "flex", justifyContent: "center" }}
      className="navbar"
    >
      <Link to="/#home" onClick={() => handleClickScroll("home")}>
        <img src="./image/E-Bike-UP-Final.png" className="logo" />
      </Link>

      {/* <a href="#home">
    <img src="./image/Icon WEB-09.png" className="logo" />
  </a> */}

      <Menu.Item key="booking">
        <a href={context.locale === "th-th" ? "https://www.asmacharge.com/rental?lang=th" : "https://www.asmacharge.com/rental?lang=en"}>
          <FormattedMessage
            id="BOOKING"
            defaultMessage="BOOKING"
            values={{
              code: (word) => <strong>{word}</strong>,
            }}
          />
        </a>
      </Menu.Item>
      <Menu.Item key="aboutUs">
        <Link to="/aboutUs" onClick={() => handleClickScroll("aboutUs")}>
          <FormattedMessage
            id="ABOUT_US"
            defaultMessage="ABOUT US"
            values={{
              code: (word) => <strong>{word}</strong>,
            }}
          />
        </Link>
        {/* <a href="aboutUs">
      <FormattedMessage
        id="ABOUT_US"
        defaultMessage="ABOUT US"
        values={{
          code: (word) => <strong>{word}</strong>,
        }}
      />
    </a> */}
      </Menu.Item>
      <Menu.Item key="evBike">
        <Link to="/#evBike" onClick={() => handleClickScroll("evBike")}>
          <FormattedMessage
            id="EV_BIKE"
            defaultMessage="EV BIKE"
            values={{
              code: (word) => <strong>{word}</strong>,
            }}
          />
        </Link>
        {/* <a href="/#evBike">
      <FormattedMessage
        id="EV_BIKE"
        defaultMessage="EV BIKE"
        values={{
          code: (word) => <strong>{word}</strong>,
        }}
      />
    </a> */}
      </Menu.Item>
      <Menu.Item key="swappingStation">
        <Link to="/website#swappingStation" onClick={() => handleClickScroll("swappingStation")}>
          <FormattedMessage
            id="SWAPPING_STATION"
            defaultMessage="SWAPPING STATION"
            values={{
              code: (word) => <strong>{word}</strong>,
            }}
          />
        </Link>
        {/* <a href="/#swappingStation">
      <FormattedMessage
        id="SWAPPING_STATION"
        defaultMessage="SWAPPING STATION"
        values={{
          code: (word) => <strong>{word}</strong>,
        }}
      />
    </a> */}
      </Menu.Item>
      <Menu.Item key="website">
        <Link to="/#website" onClick={() => handleClickScroll("website")}>
          <FormattedMessage
            id="WEBSITE"
            defaultMessage="WEBSITE"
            values={{
              code: (word) => <strong>{word}</strong>,
            }}
          />
        </Link>
        {/* <a href="#website">
      <FormattedMessage
        id="WEBSITE"
        defaultMessage="WEBSITE"
        values={{
          code: (word) => <strong>{word}</strong>,
        }}
      />
    </a> */}
      </Menu.Item>
      <Menu.Item key="promotion">
        <Link to="/#promotion" onClick={() => handleClickScroll("promotion")}>
          <FormattedMessage
            id="PROMOTION"
            defaultMessage="PROMOTION"
            values={{
              code: (word) => <strong>{word}</strong>,
            }}
          />
        </Link>
        {/* <a href="#promotion">
      <FormattedMessage
        id="PROMOTION"
        defaultMessage="PROMOTION"
        values={{
          code: (word) => <strong>{word}</strong>,
        }}
      />
    </a> */}
      </Menu.Item>
      <Menu.Item key="contactUs">
        <Link to="/#contactUs" onClick={() => handleClickScroll("contactUs")}>
          <FormattedMessage
            id="CONTACT_US"
            defaultMessage="CONTACT US"
            values={{
              code: (word) => <strong>{word}</strong>,
            }}
          />
        </Link>
        {/* <a href="#contactUs">
      <FormattedMessage
        id="CONTACT_US"
        defaultMessage="CONTACT US"
        values={{
          code: (word) => <strong>{word}</strong>,
        }}
      />
    </a> */}
      </Menu.Item>
      {/* <Menu.Item key="select-lang">
    <Select defaultValue={"th-th"} style={{ paddingTop: 6 }}
      value={context.locale}
      onChange={context.selectLanguage}>
      <Option value="en-gb">
        <GB style={styleFlagIcon} />
        {`English`}
      </Option>
      <Option value="th-th">
        <TH style={styleFlagIcon} />
        {`ไทย`}
      </Option>
    </Select>
  </Menu.Item> */}
      {/* <div key="select-lang" className='select-lang' style={{order:8}}>
    <GB style={styleFlagIcon} onClick={() => context.selectLanguage("en-gb")} />
    <TH style={styleFlagIcon} onClick={() => context.selectLanguage("th-th")} />
  </div> */}
  <Menu.Item key="signup-signing">
    <a href={context.locale === "th-th" ? "https://www.asmacharge.com/rental/member/login?lang=th" : "https://www.asmacharge.com/rental/member/login?lang=en"}>
        <FormattedMessage
          id="SIGNUP-SIGNIN"
          defaultMessage="SIGN UP/SIGN IN"
          values={{
            code: (word) => <strong>{word}</strong>,
          }}
        />
      </a>
    </Menu.Item>
      <Menu.Item key="select-lang" className='select-lang' style={{ paddingTop: 10 }}>

        {/* <Dropdown menu={menuProps} trigger={['click']} placement="bottom">
      <Space>
      {context.locale === "th-th" ?  <TH style={styleFlagIcon} onClick={() => context.selectLanguage("th-th")} />:  <GB style={styleFlagIcon} onClick={() => context.selectLanguage("en-gb")} />}
      <AiFillCaretDown style={{paddingBottom:8,color:"#6c757d"}}/>
      </Space>
    </Dropdown> */}

        <Popover content={content} trigger="click" >
          <Space style={{ cursor: 'pointer' }}>
            {context.locale === "th-th" ? <TH style={styleFlagIcon} onClick={() => context.selectLanguage("th-th")} /> : <GB style={styleFlagIcon} onClick={() => context.selectLanguage("en-gb")} />}
            <AiFillCaretDown style={{ paddingBottom: 8, color: "#6c757d" }} />
          </Space>
        </Popover>
        {/* <Popover
     content={
      <Select defaultValue={"th-th"} style={{ paddingTop: 6 }}
      value={context.locale}
      onChange={context.selectLanguage}>
      <Option value="en-gb">
        <GB style={styleFlagIcon} />
        {`English`}
      </Option>
      <Option value="th-th">
        <TH style={styleFlagIcon} />
        {`ไทย`}
      </Option>
    </Select>
    }
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      {context.locale === "th-th" ?  <TH style={styleFlagIcon} onClick={() => context.selectLanguage("th-th")} />:  <GB style={styleFlagIcon} onClick={() => context.selectLanguage("en-gb")} />}
    </Popover> */}



        {/* <Button type={context.locale === "th-th" ? "primary" : "default"} shape="circle" className='ant-btn-lang' onClick={() => context.selectLanguage("th-th")}>
        ไทย
      </Button>
      <Button type={context.locale === "en-gb" ? "primary" : "default"} shape="circle" className='ant-btn-lang' onClick={() => context.selectLanguage("en-gb")}>
        EN
      </Button> */}

      </Menu.Item>

    </Menu>
  )
}
