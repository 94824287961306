import React from "react";
import "../../App.css";

import NavBar from "../../Views/NavBar";
import Home from "../../Views/Home";
// import AboutUs from "../../Views/AboutUs";
import EvBike from "../../Views/EvBike";
import SwappingStation from "../../Views/SwappingStation";

import Website from "../../Views/Website";
import Rental from "../../Views/Rental";
import Promotion from "../../Views/Promotion";
import Map from "../../Views/Map";
import ContactUs from "../../Views/ContactUs";
import News from "../../Views/News";

function App() {
  return (
    <>
      <div className="header">
        <div className="">
          <nav>
            <NavBar />
          </nav>
        </div>
      </div>
      <div className="container">
        <Home />
        {/* <AboutUs /> */}
        <EvBike />
        <SwappingStation />
        <Website />
        <Rental />
        <Promotion />
        <Map />
        <News />
        <ContactUs />
      </div>
    </>
  );
}

export default App;
