import React from 'react'
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useParallax } from 'react-scroll-parallax'
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Row, Col, Card, Modal } from "antd";

export default function News() {

  const handleClickScroll = (name) => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 200);
  };

  const properties = {
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    indicators: false,
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    // height: "25vh",
    width: "100%",
    borderRadius: 8,
    margin: 8
  };
  const slideImages = [
    {
      image: "./image/news1.jpg",
      caption: "Slide 1",
      link: "/news?id=1"
    },
    {
      image: "./image/news2.jpg",
      caption: "Slide 2",
      link: "/news?id=2"
    }
  ];

  const parallax = useParallax({
    easing: "easeOutQuad",
    opacity: [0, 2]
  });

  return (
    <>
      <section id="news">

        <div ref={parallax.ref} className="slide-container" style={{paddingBottom:32}}>
          <h1 style={{ textAlign: "center" }} className="h1-evBike">NEWS</h1>
          <Row gutter={[16, 16]} className="row-content" justify="center">
            {
              slideImages.map((slideImage, index) => (
                <Col sm={24} md={8} key={index}>
                  <Link to={`${slideImage.link}`} onClick={handleClickScroll}>
                  <img src={slideImage.image} style={{ ...divStyle, marginLeft: "auto", marginRight: "auto" }} />
                    <div className='more'>
                      <FormattedMessage
                        id="SEE_MORE"
                        defaultMessage="See more"
                        values={{
                          code: (word) => <strong>{word}</strong>,
                        }}
                      /> {">"}
                    </div>
                  </Link>
                </Col>
              ))
            }
          </Row>

          {/* <Slide {...properties} >
            {slideImages.map((slideImage, index) => (
              <div key={index}>
                <div
                  style={{
                    ...divStyle,
                    backgroundImage: `url(${slideImage.image})`,
                  }}
                >
                </div>
                <Link to={`${slideImage.link}`} onClick={handleClickScroll}>
                  <div className='more'>
                    <FormattedMessage
                      id="SEE_MORE"
                      defaultMessage="See more"
                      values={{
                        code: (word) => <strong>{word}</strong>,
                      }}
                    /> {">"}
                  </div>
                </Link>
              </div>
            ))}
          </Slide> */}
        </div>
      </section>
    </>
  )
}
